<template>
    <v-card
      :to="data.type == 'AUTO_TEAM' || data.type == 'CUSTOM_TEAM' ? {name: 'profileGroupsEvent', params: {id: data.id, eventId: data.event_id}} : {name: routeName || 'group', params: {id: data.id}}"
      >
      <v-img
        v-if="data.img"
        :src="data.img"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <v-card-title>{{data.name}}<v-icon v-if="icon" color="white" class="ml-2">{{icon}}</v-icon></v-card-title>
      </v-img>
      <v-sheet v-else :color="$helpers.getGradientColor(index)" height="200">
        <v-card-title>{{data.name}}<v-icon v-if="icon" class="ml-2">{{icon}}</v-icon></v-card-title>
      </v-sheet>
    </v-card>
</template>

<script>
import Vue from "vue";

export default {
  name: "GroupCard",
  props: {
    item: Object,
    index: Number,
    routeName: String,
    icon: String,
  },
  data: function() {
    return {
      data: null,
      score: null,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  computed: {
  },
  watch: {
    item: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        //console.log('Prop changed: ', val, ' | was: ', oldVal)
        if (val.o) {
          // scored list
          this.data = val.o;
          this.score = val.d || val.s;
        }
        else {
          this.data = val;
        }
      }
    }
  }
};
</script>

<style scoped>
</style>