<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <Header title="Your groups" />
      <v-card-text>
        View the groups and teams you have joined.
      </v-card-text>
      <v-card-text v-if="groups && groups.length == 0">
        <i>You haven't joined any groups (yet).</i>
      </v-card-text>

      <v-row dense class="mx-4">
        <v-col v-for="(item, idx) in groups" :key="item.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <GroupCard :item="item" :index="idx" route-name="profileGroupsView"></GroupCard>
        </v-col>
      </v-row>

      <v-card-title class="headline">Groups you have created</v-card-title>
      <v-card-text>
        View and manage the groups you have created.
      </v-card-text>
      <v-card-text v-if="managedGroups && managedGroups.length == 0">
        <i>You haven't created any groups (yet).</i>
      </v-card-text>
      <p v-if="tenant.id != 'cofi'"><v-btn outlined color="primary" :to="{name:'profileGroupsCreate'}" class="ml-4">Create a new group</v-btn></p>

      <v-row dense class="mx-4">
        <v-col v-for="(item, idx) in managedGroups" :key="item.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <GroupCard :item="item" :index="idx" icon="fa-pencil-alt" route-name="profileGroupsEdit"></GroupCard>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>



<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import profileService from "@/services/profileService";
import GroupCard from "@/components/GroupCard";
import Header from '../_Header.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileGroups",
  components: {
    GroupCard,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Groups', disabled: true },
      ],
      groups: null,
      managedGroups: null,
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
        this.groups = (await profileService.myGroups()).data.data;
        this.managedGroups = (await profileService.myManagedGroups()).data.data;
      }
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

