<template>
  <div>
  <v-toolbar dark color="profile">
    <v-toolbar-title class="ml-4"><router-link class="white--text no-decoration" :to="{name:'profile' }">{{ title || $t('profile.heading')}}</router-link></v-toolbar-title>
    <v-spacer></v-spacer>
    <div>
      <slot></slot>
      <v-btn v-if="showEdit" :to="{name:'profileEdit' }" class="mr-0">
        <v-icon small class="mr-2">fadl fa fa-user-edit</v-icon>
        {{ $t('shared.edit') }}
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" title="More options">
            <v-icon >fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :href="tenant.profileFaqUrl" target="_blank">
            <v-list-item-icon><v-icon>fal fa-question-circle</v-icon></v-list-item-icon>
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <template v-slot:extension v-if="!focused">
        <v-tabs
          class="mx-0"
          background-color="profile darken-1"
          slider-color="white"
          show-arrows
        >
          <v-tab
            v-for="(tab, idx) in tabs"
            :key="idx"
            :to="tab.to"
          >
            {{ tab.text }} 
          </v-tab>
        </v-tabs>
      </template>    
  </v-toolbar>
  </div>
</template>

<script>
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileHeader",
  components: {
  },
  props: {
    title: String,
    focused: Boolean,
    showEdit: Boolean,
  },
  data() {
    return {
      tenant: tenant,
    };
  },
  async mounted() {
  },
  methods: {

  },
  computed: {
    tabs() {
      return [
        { text: this.$t('profile.title'), to: {name:'profile'} },
        { text: this.$t('profile.activities.title-short'), to: {name:'activities'} },
        { text: this.$t('profile.friends.title'), to: {name:'profileFriends'} },
        { text: this.$t('app.nav.feed'), to: {name:'profileFeed'} },
        tenant.id == "wmm" ? null : { text: this.$t('profile.groups.title'), to: {name:'profileGroups'} },
      ].filter(x => x != null);
    }
  },
};
</script>
<style lang="scss">
</style>

